<template lang="pug">
div.footer
  Contactenos(:show.sync='showContactenos')
  nav.container-fluid.text-center.text-white.mt-5
    .row.text-center.k-show
      .col
        .d-flex.k-content.mt-2.mb-2
          div.px-2
            img.img-fluid(src='@/assets/header/baseline-phone-gray.png' width='26')
            p.m-0.pt-1 01 6308 500
          div.px-2
            img.img-fluid(src='@/assets/header/baseline-email-gray.png' width='26')
            p.m-0.pt-1 contacto@mafperu.com
    .row
      .col.mw-10.mx-0.px-0.k-hide-mob(style="max-width: 23%;")
      .col.mw-80.flex(style="border-style: solid; border-color: #FFF; border-width: 1.5px 0 0 0;")
        .d-md-flex
          .col.px-1.mt-2(style="display:flex;justify-content:center;")
            .row.justify-content-center.mx-1.p-2.py-3(style="width:200px; border: 1.5px solid; border-color: #4A4A4A; border-radius: 4px")
              a(href='javascript:void(0)' @click='irLibroReclamaciones')
                img.img-fluid(src='@/assets/footer/002_wot.png' style='width:52px;')
              p.text-center.font-footer-icon.fontSize Libro de Reclamaciones
              //- p.text-center.font-footer-icon.mt-3 Reclamaciones

          .col.px-1.mt-2(style="display:flex;justify-content:center;")
            .row.justify-content-center.mx-1.p-2.py-3(style="width:200px; border: 1.5px solid; border-color: #4A4A4A; border-radius: 4px")
              a(@click='irAyuda' style='cursor: pointer;')
                img.img-fluid(src='@/assets/footer/001_wot.png'  style='width:52px;')
              p.text-center.font-footer-icon.mt-2.pt-1.fontSize Ayuda

          .col.px-1.mt-2(style="display:flex;justify-content:center;")
            .row.justify-content-center.mx-1.pt-2.pb-2.py-3(style="width:220px; border: 1.5px solid; border-color: #4A4A4A; border-radius: 4px")
              a(href='javascript:void(0)' @click='irTransparencia')
                img.img-fluid(src='@/assets/footer/003_wot.png' style='width:52px;')
              p.text-center.font-footer-icon.fontSize Transparencia de la Información
              //- p.text-center.font-footer-icon.mt-3 la Información

      .col.mw-10.mx-0.px-0.k-hide-mob(style="max-width: 23%;")
    .row.justify-content-center.mt-5
      img.img-fluid(src='@/assets/header/logonuevo.png' style='width:180px; ')
</template>

<script>
import Contactenos from '@/views/Modals/Contactenos.vue';

export default {
  name: 'KFooter',
  data: () => ({
    showContactenos: false,
  }),
  components: {
    Contactenos,
  },
  methods: {
    irTransparencia() {
      window.open('https://mafperu.com/informacion-al-usuario/#info-productos', '_blank');
    },
    irAyuda() {
      window.open('https://mafperu.com/preguntas-frecuentes/', '_blank');
    },
    irLibroReclamaciones() {
      // window.open('https://mafperu.com/wp-content/uploads/2016/05/Folleto-ATENCION-DE-REQUERIMIENTOS-Y-RECLAMOS-Ene16.pdf', '_blank');
      window.open('https://mafperu.com/libro-de-reclamaciones/', '_blank');
    },
  },
};
</script>

<style lang="scss" scoped="">
/* .footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
} */
.text-gray {
  color: #a5a5a5;
}

.fontSize{
  font-size: 16px !important;
  font-family: 'BebasNeuePro-Bold';
  line-height: normal !important;
  color: #4A4A4A !important;
}

.k-hide-mob {
  display: block;
  visibility: visible;
  margin-top: 10px;
}

.k-hide-w {
  display: none;
  visibility: hidden;
  margin-top: 10px;
}

.k-show {
  display: none;
  visibility: hidden;
  font-size: 10px;
  color: #a5a5a5;
  border-style: solid;
  border-color: #a5a5a5;
  border-width: 1px 0;
}

.font-footer-icon {
  font-size: 9px;
  margin-bottom: 0px;
  color: #4A4A4A;
  line-height: 8.5px;
}

@media only screen and (max-width:620px) {
  .k-show {
    display: block;
    visibility: visible;
    font-size: 9px;
    color: #a5a5a5;
    border-color: #a5a5a5;
    border-width: 1px 0 0 0;
  }

  .k-content {
    justify-content: center;
  }

  .k-hide-mob {
    display: none;
    visibility: hidden;
  }

  .k-hide-w {
    display: block;
    visibility: visible;
    margin-top: 10px;
  }

  .k-float {
    float: center;
  }
}</style>
